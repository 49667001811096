import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OfflineTableComponent, OfflineTableOptions } from '@shared/controls/table/offline-table.component';
import { OnlineTableComponent, OnlineTableOptions } from '@shared/controls/table/online-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { UserService } from '@shared/services/user.service';
import jwt_decode from 'jwt-decode';

import { OnlineStatusService } from '@shared/services/online-status.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { first, Subject, takeUntil } from 'rxjs';
import { SettingService } from '@shared/services/setting.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { AuthService } from '@capturum/auth';
import { User } from '@domain/models/user.model';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-project-overview',
  templateUrl: 'project-overview.component.html',
  styleUrls: ['project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('projectSearchTable', { static: false })
  projectSearchTable: OnlineTableComponent;

  @ViewChild('projectOpenTable', { static: false })
  projectOpenTable: OfflineTableComponent;

  public projectOpenTableOptions: OfflineTableOptions;
  public projectSearchTableOptions: OnlineTableOptions;
  public displayModal: boolean;
  public selectedProjectType: string;
  public specialties: any[];
  public activities: any[];
  public numberOfSpecialties: number;
  public numberOfActivities: number;
  public onlineMode = navigator.onLine;
  public searchTableReady = false;
  public $searchTableSubject = new Subject<void>();
  public authenticatedUser: User;
  public clientTypeOptions = [
    {
      label: 'Particulier',
      value: 'private',
    },
    {
      label: 'Zakelijk',
      value: 'business',
    },
  ];

  private tenantCode: string;
  private destroy$: Subject<void> = new Subject<void>();

  public constructor(
    public api: ApiServiceWithLoaderService,
    private projectService: ProjectService,
    private synchronisationService: SynchronisationService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private onlineStatusService: OnlineStatusService,
    private settingService: SettingService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private authService: AuthService,
    private permissionService: NgxPermissionsService
  ) {
    this.displayModal = false;
    this.selectedProjectType = 'private';
    this.specialties = [];
    this.activities = [];
    this.numberOfSpecialties = 0;
    this.numberOfActivities = 0;
  }

  public async ngOnInit(): Promise<any> {
    this.authenticatedUser = this.authService.getUser() as any;
    this.api.increaseLoaderValueByOne();

    this.synchronisationService
      .getTenantCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe((tenantCode) => {
        return (this.tenantCode = tenantCode);
      });

    this.$searchTableSubject.pipe().subscribe(() => {
      this.loadSearchProjectTable();
    });

    this.loadOpenProjectTable();

    await this.synchronisationService.updateEditingByFlags();
    // Always synchronise when opening project overview
    await this.synchronisationService.synchronise();

    this.api.decreaseLoaderValueByOne();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadOpenProjectTable(): void {
    this.searchTableReady = false;

    this.projectOpenTableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Type', name: 'type' },
        { title: 'Referentie', name: 'reference_nr_display' },
        { title: 'Status', name: 'status' },
        { title: 'Klant', name: 'clientName' },
        { title: 'Accountbeheerder', name: 'accountmanagerName' },
      ],
      withDelete: true,
      url: '/project',
      tableName: 'projects',
      search: false,
      rowDataTransformer: (rows: any) => {
        for (const row of rows) {
          row.reference_nr_display = row.reference_nr ? row.reference_nr : '*Wordt gegenereerd na synchronisatie*';

          row.status = row.status_base_data_value_id
            ? this.translateService.instant(`base-data.${row.status_base_data_value_id}`)
            : '-';

          row.type = this.translateService.instant(`base-data.${row.type_base_data_value_id}`);
        }

        this.$searchTableSubject.next();

        return rows;
      },
    });
  }

  public loadSearchProjectTable(): void {
    let preGlobalFilter = '';

    if (this.route?.snapshot?.queryParamMap.get('globalFilter')) {
      preGlobalFilter = this.route.snapshot.queryParamMap.get('globalFilter');

      // Remove globalFilter after search table is filtered
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} });
    }

    let columns = this.settingService.getValueString('movers_complete.project_table_columns_config');

    columns = JSON.parse(columns);

    columns[columns.length - 1].name = 'editingBy.name';

    columns?.map((column) => {
      if (column.filter && column.filter.endpoint) {
        column.filter.options = this.api.get(column.filter.endpoint);
      }

      return column;
    });

    if (this.settingService.getValue('movers_complete.api_planning_module')) {
      columns?.push({ title: 'Planning', name: 'planning' });
    }

    this.projectSearchTableOptions = new OnlineTableOptions({
      columns: columns,
      endpoint: '/project',
      search: true,
      rowDataTransformer: this.transformRows.bind(this),
      withDelete: !!this.permissionService.getPermission('project.destroy'),
      defaultFilters: { global: { value: preGlobalFilter, matchMode: 'contains' } },
      listOptions: {
        include: ['accountmanager', 'client', 'editingBy', 'quotation'],
      },
    });

    this.searchTableReady = true;
  }

  public async onOpenProjectTableRowClick(data: any): Promise<void> {
    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async onSearchProjectTableRowClick(data: any): Promise<void> {
    if (data.editingBy?.name) {
      this.confirmationService.confirm({
        message: 'Dit project wordt al bewerkt door ' + data.editingBy.name,
        header: 'Waarschuwing!',
        icon: 'fa fa-question-circle',
        acceptLabel: 'Openen in lees-modus',
        rejectLabel: 'Sluiten',
        accept: async (_) => {
          await this.openSelectedProject(data);
        },
      });
    } else {
      await this.openSelectedProject(data);
    }
  }

  public async openSelectedProject(data: any): Promise<void> {
    // Load project data from server and then navigate to detail view
    this.projectSearchTable.loading = true;
    await this.synchronisationService.loadSingleProjectData(data.id);
    this.projectSearchTable.loading = false;

    this.router.navigateByUrl('/admin/project/' + data.id + '/client');
  }

  public async addNewProject(): Promise<void> {
    const project = await this.projectService.newProject();

    this.router.navigateByUrl(`/admin/project/${project.id}/client`);
  }

  public async closeOpenProjects(): Promise<void> {
    if (!this.projectOpenTable.selectedRows || this.projectOpenTable.selectedRows.length === 0) {
      this.toastService.warning(
        this.translateService.instant('movers_complete.entity.project.plural'),
        this.translateService.instant('movers_complete.project.nothing_selected.error.text')
      );

      return;
    }

    let notSyncableProjectReferences = '';

    for (let i = 0; i < this.projectOpenTable.selectedRows.length; i++) {
      const project: Project = this.projectOpenTable.selectedRows[i];

      if (project.editing_by && +project.editing_by !== +(jwt_decode(localStorage.getItem('token')) as any).sub) {
        notSyncableProjectReferences += project.reference_nr + '\n';
      }
    }

    this.confirmationService.confirm({
      message:
        notSyncableProjectReferences.length > 0
          ? 'Let op! De volgende projecten worden niet gesynchroniseerd bij het sluiten:\n ' +
            notSyncableProjectReferences
          : 'Let op! De volgende projecten worden gesynchroniseerd en gesloten.',
      header: 'Bevestiging',
      acceptLabel: 'Akkoord',
      rejectLabel: 'Annuleren',
      icon: 'fa fa-question-circle',
      accept: async (_) => {
        this.api.increaseLoaderValueByOne();
        this.$searchTableSubject.pipe(first()).subscribe(() => {
          this.loadSearchProjectTable();
        });

        this.onlineStatusService
          .checkStatus()
          .pipe(takeUntil(this.destroy$))
          .subscribe(async (isOnline) => {
            if (isOnline === true) {
              this.projectOpenTable.loading = true;

              const closeProjectIds: number[] = this.projectOpenTable.selectedRows.map((row) => {
                return row.id;
              });
              const result = await this.synchronisationService.syncToBackend(true, closeProjectIds);

              if (!result) {
                this.toastService.error(
                  this.translateService.instant('movers_complete.entity.project.plural'),
                  this.translateService.instant('movers_complete.project.sync.error.text')
                );

                return;
              }

              // Retrieve open projects except selected rows
              for (const row of this.projectOpenTable.rows) {
                if (closeProjectIds.indexOf(row.id) === -1) {
                  await this.synchronisationService.loadSingleProjectData(row.id);
                }
              }

              this.loadOpenProjectTable();
              await this.synchronisationService.updateEditingByFlags();
              this.projectOpenTable.loading = false;

              await this.synchronisationService.synchronise();
            } else {
              this.toastService.warning(
                this.translateService.instant('movers_complete.entity.project.plural'),
                this.translateService.instant('movers_complete.project.sync_connection.error.text')
              );
            }

            this.api.decreaseLoaderValueByOne();
          });
      },
    });
  }

  /**
   * Toggle display boolean for showing the print modal
   *
   * @param event
   */
  public toggleModalDisplay(event: any): void {
    this.displayModal = true;
  }

  /**
   * Print the blank invoice based on project type
   */
  public printBlankInvoice(): void {
    this.displayModal = false;

    const divToPrint = document.getElementById('blank_invoice_pdf');
    const head = document.getElementsByTagName('head')[0];
    const popupWin = window.open('', '_blank', 'width=1024,height=768');

    popupWin.document.open();
    popupWin.document.write(
      '<html>' +
        ' <head>' +
        head.innerHTML +
        '</head>' +
        ' <body>' +
        '  <img src="/assets/' +
        this.tenantCode +
        '/images/logo.png" style="width: 175px;"><br /><br />' +
        '   ' +
        divToPrint.innerHTML +
        '' +
        ' </body>' +
        '</html>'
    );

    const elements = popupWin.document.getElementsByTagName('h5');

    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'font-size: 18px; font-weight: bold;');
    }

    /** Timeout to make sure the window is loaded */
    setTimeout(() => {
      return popupWin.print();
    }, 300);
    popupWin.document.close();
  }

  public setSpecialtiesLength(): void {
    if (!(Number(this.numberOfSpecialties) && Number(this.numberOfSpecialties < 15))) {
      return;
    }

    const length = Number(this.numberOfSpecialties);

    this.specialties = [];
    for (let i = 0; i < length; i++) {
      this.specialties.push(i);
    }
  }

  public setActivitiesLength(): void {
    if (!(Number(this.numberOfActivities) && Number(this.numberOfActivities < 15))) {
      return;
    }

    const length = Number(this.numberOfActivities);

    this.activities = [];
    for (let i = 0; i < length; i++) {
      this.activities.push(i);
    }
  }

  public setType(projectType: string): void {
    this.selectedProjectType = projectType;
  }

  private transformRows(rows: Array<any>): any {
    // Determine open projects
    const openProjectIDs = this.projectOpenTable.rows
      ? this.projectOpenTable.rows.map((project) => {
          return project.id;
        })
      : [];
    const selectedProjectIds = this.projectOpenTable.selectedRows
      ? this.projectOpenTable.selectedRows.map((project) => {
          return project.id;
        })
      : [];
    const hideProjectIds = openProjectIDs.filter((projectID) => {
      return !selectedProjectIds.includes(projectID);
    });
    const existingRows = rows.filter((row) => {
      return !hideProjectIds.includes(row.id);
    });

    for (const row of existingRows) {
      row.status = Project.getStatusName(row.status);
      row.status_base_data_value_id = this.translateService.instant(`base-data.${row.status_base_data_value_id}`);
      row.type_base_data_value_id = this.translateService.instant(`base-data.${row.type_base_data_value_id}`);
    }

    return existingRows;
  }
}
