<app-dialog-layout *ngIf="project">
  <ng-container content>
    <cpb-form-renderer
      #formRenderer
      [formKey]="formKey"
      [modelId]="modelId"
      [context]="{ project_id: project.id }"
      [showHeader]="false"></cpb-form-renderer>
  </ng-container>

  <ng-container buttons>
    <cap-button styleClass="primary" (click)="onCancel()">
      <i class="fa fa-ban"></i> {{ 'button.cancel' | translate }}
    </cap-button>

    <cap-button styleClass="primary" (click)="onSubmit()">
      <i class="fa fa-check"></i> {{ 'button.submit' | translate }}
    </cap-button>
  </ng-container>
</app-dialog-layout>
