import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Quotation } from '@domain/models/quotation.model';
import * as moment from 'moment';
import { SignatureComponent } from '@root/src/app/shared/controls/signature/signature.component';
import { QuotationSignService } from '@shared/services/quotation-sign.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from '@domain/models/contact.model';
import { Address } from '@domain/models/address.model';
import { Client } from '@domain/models/client.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import { ProjectType } from '@core/enums/project-type.enum';
import { CapturumListRendererComponent } from '@node_modules/@capturum/builders/list-renderer';
import { Inventory } from '@domain/models/inventory.model';
import { QuotationCheck } from '@domain/models/quotation-check.model';

interface InventoryTableItem {
  name: string;
  totalAmountOfItems: number;
  totalPrice: number;
  totalPriceWithVAT: number;
}

@Component({
  selector: 'app-project-quotation-sign',
  templateUrl: 'quotation-sign.component.html',
  styleUrls: ['./quotation-sign.component.scss'],
})
export class QuotationSignComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature', { static: false })
  public clientSignature: SignatureComponent;

  @ViewChild('valuatorSignature', { static: false })
  public valuatorSignature: SignatureComponent;

  @ViewChild('listRenderer')
  public listRenderer: CapturumListRendererComponent;

  public movingCostsOptions = [];
  public showPricesExclVat = false;
  public quotation: Quotation;
  public localeNL: CalendarLocale = new CalendarLocale();
  public disabled: boolean;
  public projectId: string;
  public quotationSignId: string;
  public today: string;
  public loading: boolean;
  public error: boolean;
  public submitted: boolean;
  public errorMessage: string;
  public project: any;
  public contacts: Contact[];
  public addresses: Address[];
  public inventories: Inventory[] = [];
  public inventoriesForTable: InventoryTableItem[] = [];
  public projectMaterials: any[];
  public projectActivities: any[];
  public quotationTasks: any[];
  public additionalCosts: any[];
  public client: Client;
  public projectSpecialties: ProjectSpecialty[];
  public totalVAT = 0;
  public subtotalPrice = 0;
  public totalsVat = [];
  public totalVatByRates: any;
  public quotationChecks: QuotationCheck[] = [];

  private subscriptionProjectLoaded: Subscription;

  constructor(
    private route: ActivatedRoute,
    private quotationService: QuotationSignService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.quotation = null;
    this.projectId = null;
    this.quotationSignId = null;
    this.loading = true;
    this.error = false;
    this.submitted = false;
    this.today = null;
    this.contacts = null;
    this.addresses = null;
    this.inventories = null;
    this.projectMaterials = null;
    this.projectActivities = null;
    this.quotationTasks = null;
    this.additionalCosts = null;
    this.totalVatByRates = null;

    this.route.params.subscribe((params) => {
      this.projectId = params['quotation_project_id'];
      this.quotationSignId = params['quotation_token'];
    });
  }

  public async ngOnInit(): Promise<void> {
    this.movingCostsOptions = [
      {
        label: this.translateService.instant('movers_complete.project.quotation.show-incl.label'),
        value: ProjectType.private,
        type: ProjectType.private,
      },
      {
        label: this.translateService.instant('movers_complete.project.quotation.show-excl.label'),
        value: ProjectType.business,
        type: ProjectType.business,
      },
    ];

    this.getQuotation();
  }

  public setVatChoice(projectType: string): void {
    this.showPricesExclVat = projectType === this.movingCostsOptions[1].value;

    this.cdr.detectChanges();
    this.inventoriesForTable = [...this.prepareInventoriesForTable()];
    this.projectSpecialties = [...this.projectSpecialties];
  }

  public listRendererDataChange(): void {
    this.calculateTotals();
  }

  public getQuotation(): void {
    this.subscriptionProjectLoaded = this.quotationService.getQuotation(this.quotationSignId, this.projectId).subscribe(
      (responseValue) => {
        const response = responseValue.data;

        if (response.contacts) {
          this.contacts = response.contacts;
        }

        if (response.addresses) {
          this.addresses = response.addresses;
        }

        if (response.inventories) {
          this.inventories = response.inventories;
        }

        if (response.project_materials) {
          this.projectMaterials = response.project_materials;
        }

        if (response.project_activities) {
          this.projectActivities = response.project_activities;
        }

        if (response.quotation_tasks) {
          this.quotationTasks = response.quotation_tasks;
        }

        if (response.project) {
          this.project = response.project;
          this.totalVatByRates = response.project.total_vat_by_rate;
        }

        if (response.project_specialties) {
          this.projectSpecialties = response.project_specialties;
        }

        if (response.client) {
          this.client = response.client;
        }

        if (response.additional_costs) {
          this.additionalCosts = response.additional_costs;
        }

        if (response.quotation_checks) {
          this.quotationChecks = response.quotation_checks;

          this.quotationChecks = this.quotationChecks.filter((check) => {
            return check.project_type_base_data_value_id == this.project.type_base_data_value_id;
          });

          this.quotationChecks = this.quotationChecks.filter(({ deleted_at }) => {
            const projectCreatedAt = new Date(this.project.created_at);

            return !deleted_at || new Date(deleted_at) > projectCreatedAt;
          });

          this.quotationChecks.map((check) => {
            check.is_selected = false;
          });
        }

        if (response.quotation) {
          response.quotation.signature_client_date = new Date();

          this.quotation = response.quotation;

          if (this.quotation.quotation_checks.length > 0) {
            this.quotation.quotation_checks.map((check) => {
              const validQuotationIndex = this.quotationChecks.findIndex((quotationCheck) => {
                return quotationCheck.id == check.id;
              });

              if (this.quotationChecks[validQuotationIndex]) {
                this.quotationChecks[validQuotationIndex].is_selected = true;
              }
            });
          }

          this.quotationChecks = this.quotationChecks.filter((check) => {
            return check.is_selected;
          });
        }

        this.disabled = true;

        if (response.status === 'error') {
          this.error = true;
          this.errorMessage = response.message;
        }

        this.loading = false;

        this.inventoriesForTable = [...this.prepareInventoriesForTable()];
        this.calculateTotals();
      },
      () => {
        this.toastService.error(
          this.translateService.instant('toast.error.title'),
          this.translateService.instant('toast.error.message')
        );
      }
    );
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public async submitQuotation(): Promise<void> {
    this.quotation.signature_client_date = this.quotation.signature_client_date
      ? moment(this.quotation.signature_client_date).format('YYYY-MM-DD HH:mm:ss')
      : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

    this.quotationService
      .signQuotation({
        quotation_sign_id: this.quotationSignId,
        quotation: this.quotation,
      })
      .subscribe(
        () => {
          this.submitted = true;
          this.toastService.success(
            this.translateService.instant('movers_complete.toast.sync.title'),
            this.translateService.instant('movers_complete.quotation.signed.success.text')
          );
        },
        () => {
          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            this.translateService.instant('toast.error.message')
          );
        }
      );
  }

  public calculateTotals(): void {
    this.subtotalPrice = 0;
    this.totalVAT = 0;

    this.projectSpecialties = this.projectSpecialties.filter((s) => {
      return s.applicable;
    });

    this.projectSpecialties.map((specialty) => {
      this.subtotalPrice = this.subtotalPrice + specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0);
      this.totalVAT =
        this.totalVAT +
        (specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0) * (1 + specialty.specialty.vat_rate / 100) -
          specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0));
    });

    this.inventoriesForTable.map((inventory) => {
      this.subtotalPrice = this.subtotalPrice + inventory.totalPrice;
      this.totalVAT = this.totalVAT + (inventory.totalPriceWithVAT - inventory.totalPrice);
    });

    this.listRenderer?.data.forEach((dataRow) => {
      this.totalsVat.push({
        amount:
          Number(dataRow.priceInclVatWithCurrency.replace('€ ', '')) -
          Number(dataRow.priceExclVatWithCurrency.replace('€ ', '')),
        vat: dataRow.vatRateWithPercentage.replace('%', ''),
      });

      this.subtotalPrice = this.subtotalPrice + Number(dataRow.priceExclVatWithCurrency.replace('€ ', ''));

      this.totalVAT =
        this.totalVAT +
        (Number(dataRow.priceInclVatWithCurrency.replace('€ ', '')) -
          Number(dataRow.priceExclVatWithCurrency.replace('€ ', '')));
    });
  }

  public prepareInventoriesForTable(): InventoryTableItem[] {
    return this.inventories
      ?.map((inventory) => {
        const name = inventory.name;

        const totalAmountOfItems = inventory?.inventory_items.reduce((acc, current) => {
          return acc + (current?.amount ?? 0);
        }, 0);

        const totalPriceWithVAT = inventory?.inventory_items.reduce((acc, current) => {
          return acc + (current?.amount ?? 0) * (current?.price ?? current?.price ?? 0) * 1.21;
        }, 0);

        const totalPrice = inventory?.inventory_items.reduce((acc, current) => {
          return acc + (current?.amount ?? 0) * (current?.price ?? current?.price ?? 0);
        }, 0);

        return {
          name,
          totalAmountOfItems,
          totalPrice,
          totalPriceWithVAT,
        };
      })
      .filter((inventory) => {
        return inventory.totalAmountOfItems > 0;
      });
  }

  public showClientSignatureForm(): void {
    this.today = this.getMoment();

    this.clientSignature.showForm();
  }

  private getMoment(): string {
    return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  }
}
