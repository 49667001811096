<nav class="header navbar navbar-static-top" role="navigation" #navbar>
  <img
    [src]="'zandbergen-logo.png' | assets : 'images' | addMyTenant | async"
    onerror="this.src = '/assets/images/zandbergen-logo.png'" />
</nav>

<div *ngIf="!loading" id="wrapper">
  <div class="row border-bottom">
    <div class="col-md-4 border-right">
      <h5>{{ 'movers_complete.project.quotation.general-info.label' | translate }}</h5>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.reference.label' | translate }}:</div>
        <div class="col-6">{{ project?.reference_nr || '-' }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.client.label' | translate }}:</div>
        <div class="col-6">{{ client?.name }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.description.label' | translate }}:</div>
        <div class="col-6">{{ client?.description }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">
          {{ 'movers_complete.project.quotation.desired-start-date.label' | translate }}:
        </div>
        <div class="col-6">{{ project?.delivery_date | date : 'dd-MM-yyyy' }}</div>
      </div>
    </div>

    <div class="col-md-4 border-right">
      <h5>{{ 'movers_complete.project.quotation.address.label' | translate }}</h5>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">
          {{ 'movers_complete.project.quotation.street-house-number.label' | translate }}:
        </div>
        <div class="col-6">{{ addresses[0]?.street || '-' }} {{ addresses[0]?.housenumber || '-' }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.postal-code.label' | translate }}:</div>
        <div class="col-6">{{ addresses[0]?.zipcode || '-' }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.city.label' | translate }}:</div>
        <div class="col-6">{{ addresses[0]?.city || '-' }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.country.label' | translate }}:</div>
        <div class="col-6">{{ addresses[0]?.country || '-' }}</div>
      </div>
    </div>

    <div class="col-md-4">
      <h5>{{ 'movers_complete.project.quotation.contact.label' | translate }}</h5>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.client-name.label' | translate }}:</div>
        <div class="col-6">
          {{ 'base-data.' + contacts[contacts.length - 1]?.gender | translate }}
          {{ contacts[contacts.length - 1]?.initials || '-' }}
          {{ contacts[contacts.length - 1]?.name || '-' }}
        </div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.email.label' | translate }}:</div>
        <div class="col-6">{{ contacts[contacts.length - 1]?.email || '-' }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.phone.label' | translate }}:</div>
        <div class="col-6">{{ contacts[contacts.length - 1]?.phone || '-' }}</div>
      </div>

      <div class="row col-12 tile-section">
        <div class="col-5 tile-tile">{{ 'movers_complete.project.quotation.remark.label' | translate }}:</div>
        <div class="col-6">{{ contacts[contacts.length - 1]?.remarks || '-' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-2 border-bottom">
    <div class="col-5">
      <h5>{{ 'movers_complete.project.quotation.pricing.label' | translate }}</h5>
    </div>

    <div class="col-3 mb-2">
      <cap-select-button
        [(ngModel)]="movingCostsOptions[0].value"
        [optionValue]="'type'"
        [options]="movingCostsOptions"
        [optionLabel]="'label'"
        (change)="setVatChoice($event.value)">
      </cap-select-button>
    </div>
  </div>

  <div class="row pb-3 mb-3 border-bottom" *ngIf="projectSpecialties.length > 0">
    <div class="col-12">
      <h5>{{ 'movers_complete.project.quotation.options.label' | translate }}</h5>
    </div>

    <div class="col-12">
      <table id="options" class="custom-table">
        <tr>
          <th>{{ 'movers_complete.project.quotation.client-name.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.remark.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.date.label' | translate }}</th>
          <th>{{ 'movers_complete.inventory.option.amount.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.total-amount.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.vat.label' | translate }}</th>
        </tr>

        <tr *ngFor="let specialty of projectSpecialties">
          <ng-container *ngIf="specialty.applicable">
            <td>{{ specialty.specialty.name }}</td>
            <td>{{ specialty.details }}</td>
            <td>{{ specialty.start | date : 'dd-MM-yyyy' }}</td>
            <td>{{ specialty.hours_estimate ?? 0 }}</td>
            <td>
              <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
                {{
                  specialty.specialty.cost_rate *
                    (specialty.hours_estimate ?? 0) *
                    (1 + specialty.specialty.vat_rate / 100) | currency : 'EUR'
                }}
              </ng-container>

              <ng-template #showPricesWithoutVat>
                {{ specialty.specialty.cost_rate * (specialty.hours_estimate ?? 0) | currency : 'EUR' }}
              </ng-template>
            </td>
            <td>{{ specialty.specialty.vat_rate }} %</td>
          </ng-container>
        </tr>
      </table>
    </div>
  </div>

  <div class="row pb-3 mb-3 border-bottom" *ngIf="inventoriesForTable.length > 0">
    <div class="col-12">
      <h5>{{ 'movers_complete.inventory.overview.inventories.label' | translate }}</h5>
    </div>

    <div class="col-12">
      <table id="inventarisation" class="custom-table">
        <tr>
          <th>{{ 'movers_complete.project.quotation.section.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.total-amount-of-items.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.total-price.label' | translate }}</th>
        </tr>

        <tr *ngFor="let inventory of inventoriesForTable">
          <td>{{ inventory.name }}</td>
          <td>{{ inventory.totalAmountOfItems }}</td>
          <td>
            <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
              {{ inventory.totalPrice * 1.21 | currency : 'EUR' }}
            </ng-container>

            <ng-template #showPricesWithoutVat>
              {{ inventory.totalPrice | currency : 'EUR' }}
            </ng-template>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="pb-3 mb-3 border-bottom" *ngIf="projectActivities && projectActivities.length > 0">
    <h5>{{ 'movers_complete.project.quotation.activities.label' | translate }}:</h5>

    <ng-container *ngFor="let activity of projectActivities | sortByDateProperty : 'start' : 'asc'; let i = index">
      <div class="row" *ngIf="activity?.applicable">
        <div class="col-3">
          <p class="line-height-2 fade-text-color">{{ activity?.activity?.name }}</p>
        </div>

        <div class="col-3">
          <p class="line-height-2 fade-text-color">
            {{ activity.number_movers ? activity.number_movers : 0 }}
            {{ 'movers_complete.project.quotation.movers.label' | translate }} /
            {{ activity.number_trucks ? activity.number_trucks : 0 }}
            {{ 'movers_complete.project.quotation.trucks.label' | translate }}
          </p>
        </div>

        <div class="col-2" *ngIf="activity?.start && activity?.start !== ''">
          <p class="line-height-2 fade-text-color">{{ activity.start | date : 'dd-MM-yyyy' }}</p>
        </div>

        <div class="col-3" *ngIf="activity?.details && activity?.details !== ''">
          <p class="line-height-2 fade-text-color">{{ activity.details }}</p>
        </div>

        <div class="col-3 spacing-address-activity" *ngIf="activity?.address">
          <p class="line-height-2 fade-text-color">
            {{ 'movers_complete.project.quotation.address.label' | translate }} {{ activity.address }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="row pb-3 mb-3 border-bottom">
    <div class="col-12">
      <h5>{{ 'movers_complete.project.quotation.free-to-fill-in.label' | translate }}</h5>
    </div>

    <div class="col-12">
      <table id="freeToFillIn" class="custom-table">
        <tr>
          <th>{{ 'movers_complete.project.quotation.client-name.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.total-price.label' | translate }}</th>
          <th>{{ 'movers_complete.project.quotation.vat.label' | translate }}</th>
        </tr>

        <tr *ngFor="let additionalCost of additionalCosts">
          <td>{{ additionalCost.label }}</td>
          <td>
            <ng-container *ngIf="!showPricesExclVat; else showPricesWithoutVat">
              {{ additionalCost.price_incl_vat | currency : 'EUR' }}
            </ng-container>

            <ng-template #showPricesWithoutVat>
              {{ additionalCost.price_excl_vat | currency : 'EUR' }}
            </ng-template>
          </td>
          <td>{{ additionalCost.vat_rate }} %</td>
        </tr>
      </table>
    </div>
  </div>

  <div class="row pb-3 mb-3 border-bottom">
    <div class="col-12">
      <h5>Totals</h5>
    </div>

    <div class="col-12">
      <table id="totals" class="custom-table">
        <tr>
          <td>Subtotal</td>
          <td>{{ subtotalPrice | currency : 'EUR' }}</td>
        </tr>

        <tr>
          <td>VAT</td>
          <td>
            <div *ngFor="let totalVatByRate of totalVatByRates | keyvalue">
              {{ totalVatByRate.value | currency : 'EUR' }} {{ totalVatByRate.key }}%
            </div>
          </td>
        </tr>

        <tr>
          <td>Total</td>
          <td>{{ subtotalPrice + totalVAT | currency : 'EUR' }}</td>
        </tr>
      </table>
    </div>
  </div>

  <div *ngIf="quotationChecks.length > 0" class="row pb-3 mb-3 border-bottom">
    <div class="col-12 agreement">
      <h5>{{ 'movers_complete.project.quotation.accept.label' | translate }}</h5>

      <p class="line-height-2 fade-text-color">
        {{ 'movers_complete.project.quotation.agreement.text1' | translate }}
      </p>

      <div *ngFor="let check of quotationChecks" class="d-flex align-items-center">
        <i class="fas fa-check check"></i>

        <p class="line-height-2 fade-text-color">{{ check.label }}</p>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-sm-6 col-md-4">
      <h5 class="line-height-2 mb-2">
        {{ 'movers_complete.project.quotation.signature-client.label' | translate }}
      </h5>

      <p class="line-height-2 mb-2 fade-text-color">
        {{ 'movers_complete.project.quotation.signature-date.label' | translate }}
      </p>

      <cap-calendar
        [disabled]="disabled"
        [(ngModel)]="quotation.signature_client_date"
        dateFormat="dd-mm-yy"
        [locale]="localeNL">
      </cap-calendar>

      <div *ngIf="quotation.signature_client_image">
        <img [src]="quotation.signature_client_image" class="signature" />
      </div>

      <cap-button
        styleClass="primary"
        class="w-100"
        [label]="'movers_complete.project.quotation.button.enter-signature' | translate"
        (onClick)="showClientSignatureForm()">
      </cap-button>

      <app-signature #clientSignature [(signature)]="quotation.signature_client_image"> </app-signature>
    </div>
  </div>

  <div class="d-flex justify-content-end mr-20">
    <cap-button label="Submit quotation" styleClass="primary" (onClick)="submitQuotation()"></cap-button>
  </div>
</div>
