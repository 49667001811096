import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { first } from '@node_modules/rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { InventoryItemDetailsComponent } from '@features/inventory/inventory/details/inventory-item-details.component';
import { ProjectService } from '@shared/services/project.service';
import { ConfirmationService } from 'primeng/api';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { DataService } from '@shared/services/data.service';
import { InventoryItem } from '@domain/models/inventory-item.model';
import { InputNumberButtonLayout } from '@capturum/ui/input-number';
import { ActionType } from '@core/enums/action-type.enum';
import { SettingService } from '@shared/services/setting.service';

@Component({
  selector: 'app-inventory-item',
  templateUrl: 'inventory-item.component.html',
})
export class InventoryItemComponent extends InventoryBoardComponent implements OnInit {
  @Input() inventoryItem;
  @Input() disabled;

  @Output() inventoryItemChanged = new EventEmitter();
  @Output() inventoryItemDeleted = new EventEmitter();

  public form: UntypedFormGroup;
  public hasBulkPrices: boolean = false;
  protected readonly InputNumberButtonLayout = InputNumberButtonLayout;

  constructor(
    projectService: ProjectService,
    confirmationService: ConfirmationService,
    dataService: DataService,
    dialogService: DialogService,
    translateService: TranslateService,
    public settingService: SettingService
  ) {
    super(projectService, confirmationService, dataService, dialogService, translateService);

    this.form = new UntypedFormGroup({
      amount: new UntypedFormControl(),
    });
  }

  public async ngOnInit(): Promise<void> {
    this.hasBulkPrices = this.settingService.getValue('movers_complete.has_bulk_prices');

    if (!this.inventoryItem.amount || this.inventoryItem.amount < 0) {
      this.inventoryItem.amount = 0;
    }
  }

  public changeAmount(amount: number) {
    if (!amount) {
      amount = 0;
    }

    const oldAmount = this.inventoryItem.amount;

    this.inventoryItem.amount = +amount;

    if (oldAmount !== this.inventoryItem.amount) {
      this.inventoryItemChanged.emit({
        ...this.inventoryItem,
        increment: oldAmount - +amount,
        subtraction: +amount <= oldAmount,
      });
    }
  }

  public openAddItemDetails(inventoryItem: InventoryItem): void {
    const dialogRef = this.dialogService.open(InventoryItemDetailsComponent, {
      header: inventoryItem.name,
      data: {
        inventoryItem,
        disabled: this.disabled,
      },
    });

    dialogRef.onClose.pipe(first()).subscribe((inventoryItem) => {
      if (inventoryItem?.typeAction === ActionType.UPDATE) {
        this.inventoryItem = inventoryItem.inventoryItem;
        this.inventoryItemChanged.emit(inventoryItem.inventoryItem);
      }

      if (inventoryItem?.typeAction === ActionType.DELETE) {
        this.inventoryItemDeleted.emit(inventoryItem?.inventoryItem);
      }
    });
  }
}
