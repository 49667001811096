import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { ProjectService } from '@shared/services/project.service';
import { Router } from '@angular/router';
import { Client } from '@domain/models/client.model';
import { Project } from '@domain/models/project.model';
import { DataService, QueryOptions } from '@shared/services/data.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UserService } from '@shared/services/user.service';
import { environment } from '@environments/environment';
import { CalendarLocale } from '@domain/models/calendar-locale.model';
import * as moment from '@node_modules/moment';
import { BaseDataIndexedDbModel } from '@capturum/complete';
import { TranslateService } from '@ngx-translate/core';
import { TitleCasePipe } from '@angular/common';
import { getBaseDataByKey, getMappedBaseDataByKey } from '@core/utils/base-data.utils';
import { ProjectStatus } from '@core/enums/project-status.enum';
import { InventoryFlowValidationService } from '@core/services/inventory-flow-validation.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { SettingService } from '@node_modules/@capturum/complete';

@Component({
  selector: 'app-inventory-client',
  templateUrl: 'client.component.html',
  styleUrls: ['./client.component.scss'],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
})

/**
 * InventoryClientComponent
 */
export class InventoryClientComponent implements OnInit, OnDestroy {
  public form: any;
  public errors: any = {};
  public showErrors = false;
  public client = new Client({});
  public project = new Project({});
  public result: any;
  public accountManagersList: SelectItem[] = [];
  public executorList: SelectItem[] = [];
  public statusList: SelectItem[] = [];
  public relationGroups: SelectItem[] = [];
  public locations: SelectItem[] = [];
  public environment: any;
  public disabled = false;
  public clientTypeOptions: any[];
  public localeNL: CalendarLocale = new CalendarLocale();
  public clients: any[] = [];
  public filteredClients: SelectItem[] = [];
  public existingClient: SelectItem = null;
  public currentDate: Date = new Date();
  public hasBoelaarsEnLambert: boolean = false;

  private canChangeStatus: boolean;
  private subscriptionProjectLoaded: Subscription;
  private subscriptionClientChanged: Subscription;
  private defaultUser: any;
  private projectTypes: BaseDataIndexedDbModel;
  public projectStatuses: { [key: string]: string };
  public isValidLocationId: boolean;

  private disabledForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: Subject<void> = new Subject<void>();

  /**
   * Constructor
   *
   * @param {FormBuilder} formBuilder
   * @param {ProjectService} projectService
   * @param {DataService} dataService
   * @param {Router} router
   * @param userService
   * @param translateService
   * @param titleCasePipe
   * @param validationService
   * @param settingService
   */
  public constructor(
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private dataService: DataService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private validationService: InventoryFlowValidationService,
    private settingService: SettingService
  ) {
    this.defaultUser = null;
    this.canChangeStatus = true;
    this.environment = environment;
  }

  public async ngOnInit(): Promise<void> {
    this.projectStatuses = await getMappedBaseDataByKey('project-status');
    this.hasBoelaarsEnLambert = this.settingService.getValue('movers_complete.api_boelaars_en_lambert');

    await this.loadLists();

    this.project = await this.projectService.getProject();
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;

      this.disabledForm$.next(this.disabled);
    });

    if (this.project.client) {
      this.client = this.project.client;

      const parentClient = this.clients.find((item) => {
        return item.value === this.client.parent_id;
      });
      let client = { label: this.client.name, value: this.client.id };

      if (parentClient && this.client && this.client.parent_id) {
        client = { label: `${parentClient.label} (${parentClient.reference_nr})`, value: parentClient.value };
      }

      this.filteredClients = [client];
      this.existingClient = client;
    }

    // In case client has changed (selected from popup)
    this.subscriptionClientChanged = this.projectService.clientChanged.subscribe((client: Client) => {
      this.project.client_id = client.id;
      this.project.client = client;
      this.client = client;
      this.updateForm();
    });

    // In case project loaded (in case of refresh)
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;

      if (this.project.client) {
        this.client = this.project.client;
      } else {
        this.client = new Client({});
      }

      this.projectService.setCurrentClient(this.client);
      this.updateForm();
    });

    this.initForm();
    this.updateForm();

    this.disabledForm$.subscribe(async (_) => {
      /** Only check for this if project is booked */
      if (this.disabled) {
        this.canChangeStatus = this.userService.isAdministrator();
        await this.updateForm();
      }
    });
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }

    if (this.subscriptionClientChanged) {
      this.subscriptionClientChanged.unsubscribe();
    }

    await this.saveClient();

    this.destroy$.next();
    this.destroy$.complete();
  }

  public setType(type): void {
    this.projectService.project.type_base_data_value_id = type;
    this.projectService.project.type = type;

    const selectedProjectType = this.projectTypes.values.find((projectType) => {
      return projectType.id === type;
    });

    this.projectService.project.projectType = selectedProjectType.value;
  }

  public searchClient(event: any): void {
    this.filteredClients = [];
    for (let i = 0; i < this.clients.length; i++) {
      const client = this.clients[i];

      if (client.label.toLowerCase().includes(event.query.toLowerCase())) {
        this.filteredClients.push({ label: `${client.label} (${client.reference_nr})`, value: client.value });
      }
    }
  }

  private async loadLists(): Promise<void> {
    let queryOptions = new QueryOptions({
      sortColumn: 'name',
      sortOrder: 'asc',
      usePaging: false,
    });

    let result = await this.dataService.get('locations', queryOptions, '/location-group/list');

    this.locations = [];
    result.forEach((item) => {
      this.locations.push({ label: item.name, value: item.id });
    }, this);

    result = await this.dataService.get('client_templates', queryOptions, '/client_templates/list');

    this.clients = [];
    result.forEach((item) => {
      if (item && !item.parent_id) {
        this.clients.push({
          label: item.name,
          value: item.id,
          reference_nr: item.reference_nr,
        });
      }
    }, this);

    result = await this.dataService.get('relation_groups', queryOptions, '/relation-group/list');
    this.relationGroups = [];
    result.forEach((item) => {
      this.relationGroups.push({ label: item.name, value: item.id });
    }, this);

    queryOptions = new QueryOptions({
      sortColumn: 'name_lcase',
      usePaging: false,
    });
    result = await this.dataService.get('users', queryOptions, '/users/list');

    this.accountManagersList = [];
    result.forEach((user) => {
      if (user && user.is_accountmanager) {
        this.accountManagersList.push({ label: user.name, value: user.id });
      }
    }, this);

    if (!this.project.accountmanager_id) {
      const selectedUser = result.find((user) => {
        return user.id === JSON.parse(localStorage.getItem('userId'));
      });

      if (selectedUser) {
        this.defaultUser = selectedUser.id;
      }
    }
    this.accountManagersList.sort((one, two) => {
      return one > two ? 1 : -1;
    });

    this.executorList = [];
    result.forEach((item) => {
      if (item?.is_executor) {
        this.executorList.push({ label: item.name, value: item.id });
      }
    }, this);

    this.statusList = await this.projectService.getStatusList();
  }

  private async initForm(): Promise<void> {
    if (!this.project.delivery_date || !moment(this.project.delivery_date, 'YYYY-MM-DD', true).isValid()) {
      this.project.delivery_date = null;
    }

    const labelRequired = (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;

      if (
        (typeof value === 'object' && (!value.label || value.label.trim() === '')) ||
        (typeof value !== 'object' && (value.length === 0 || value.trim() === ''))
      ) {
        return { required: true };
      }

      return null;
    };

    this.projectTypes = await getBaseDataByKey('project-type');

    this.clientTypeOptions = this.projectTypes.values
      .map((type) => {
        return {
          label: this.titleCasePipe.transform(this.translateService.instant(`base-data.${type.id}`)),
          value: type.id,
          disabled: this.project?.status_base_data_value_id === this.projectStatuses[ProjectStatus.booked],
        };
      })
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }

        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1;
        }

        return 0;
      });

    this.form = this.formBuilder.group({
      client_type: this.formBuilder.control(
        {
          value: this.project?.type_base_data_value_id,
          disabled: this.disabled,
        },
        [Validators.required]
      ),
      accountmanager_id: this.formBuilder.control(
        {
          value: this.defaultUser || this.project.accountmanager_id,
          disabled: this.disabled,
        },
        [Validators.required]
      ),
      executor_id: this.formBuilder.control({
        value: this.project.executor_id,
        disabled: this.disabled,
      }),
      name: this.formBuilder.control(
        {
          value: this.existingClient ? this.existingClient : { label: '', value: '' },
          disabled: this.disabled,
        },
        [labelRequired, Validators.maxLength(255)]
      ),
      delivery_date: this.formBuilder.control(
        {
          value: this.project.delivery_date ? new Date(this.project.delivery_date) : null,
          disabled: this.disabled,
        },
        this.hasBoelaarsEnLambert ? [Validators.required] : []
      ),
      location_id: this.formBuilder.control(
        {
          value: this.client.location_id || '',
          disabled: this.disabled,
        },
        [Validators.required]
      ),
      relation_group_id: this.formBuilder.control(
        {
          value: this.client.relation_group_id ? this.client.relation_group_id : '',
          disabled: this.disabled,
        },
        [Validators.required]
      ),
      status: this.formBuilder.control(
        {
          value: this.project.status_base_data_value_id || '',
          disabled: !this.canChangeStatus,
        },
        [Validators.required]
      ),
      description: this.formBuilder.control({
        value: this.client.description || '',
        disabled: this.disabled,
      }),
      remarks: this.formBuilder.control({ value: this.client.remarks || '', disabled: this.disabled }),
    });

    this.validation();
  }

  private updateForm(): void {
    if (!this.project.delivery_date || !moment(this.project.delivery_date, 'YYYY-MM-DD', true).isValid()) {
      this.project.delivery_date = null;
    }

    this.form?.reset({
      client_type: { value: this.project.type_base_data_value_id, disabled: this.disabled },
      accountmanager_id: { value: this.defaultUser || this.project.accountmanager_id, disabled: this.disabled },
      executor_id: { value: this.project.executor_id, disabled: this.disabled },
      name: { value: this.existingClient ? this.existingClient : { label: '', value: '' }, disabled: this.disabled },
      location_id: {
        value: this.client.location_id ?? (this.locations.length > 0 ? this.locations[0].value : null),
        disabled: this.disabled,
      },
      relation_group_id: {
        value: this.client.relation_group_id ? this.client.relation_group_id : '',
        disabled: this.disabled,
      },
      status: { value: this.project.status_base_data_value_id || '', disabled: !this.canChangeStatus },
      description: { value: this.client.description || '', disabled: this.disabled },
      delivery_date: {
        value: this.project.delivery_date ? new Date(this.project.delivery_date) : null,
        disabled: this.disabled,
      },
      remarks: { value: this.client.remarks || '', disabled: this.disabled },
    });
  }

  private async saveClient(): Promise<void> {
    if (this.form.valid) {
      const nameField = this.form.get('name').value;
      const parentClient = this.clients.find((client) => {
        return client.value === nameField.value;
      });

      if (parentClient) {
        this.client.name = nameField.label;
        this.client.parent_id = parentClient.value;
      } else {
        const client = this.form.get('name').value;

        this.client.name = client && client.label ? client.label : client && !client.label ? client : null;
        this.client.parent_id = null;
      }

      if (this.client.id === this.client.parent_id) {
        this.client.parent_id = null;
      }

      this.client.location_id = this.form.get('location_id').value;
      this.client.relation_group_id = this.form.get('relation_group_id').value;
      this.client.description = this.form.get('description').value;
      this.client.remarks = this.form.get('remarks').value;

      this.project.client = this.client;
      this.project.status = this.form.get('status').value;
      this.project.status_base_data_value_id = this.form.get('status').value;
      this.project.accountmanager_id = this.form.get('accountmanager_id').value;
      this.project.executor_id = this.form.get('executor_id').value;
      this.project.delivery_date = this.form.get('delivery_date').value
        ? moment(this.form.get('delivery_date').value).format('YYYY-MM-DD')
        : null;

      if (!this.project.delivery_date || !moment(this.project.delivery_date, 'YYYY-MM-DD', true).isValid()) {
        this.project.delivery_date = null;
      }

      await this.projectService.saveClientAndProject();

      this.projectService.setCurrentClient(this.client);
    }
  }

  private validation(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const locationValues =
        this.locations.map((item) => {
          return item?.value;
        }) || [];

      this.isValidLocationId = locationValues.includes(this.form.controls['location_id'].value);

      this.validationService.setValidation(this.form?.valid && this.isValidLocationId);
    });
  }

  public openTimeline(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/client(popup:admin/project/client/events)');
    }
  }
}
