import { Component, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { CapturumFormRendererComponent, FormSaverService } from '@capturum/builders/form-renderer';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';

@Component({
  selector: 'app-picture-item-detail',
  templateUrl: './picture-item-detail.component.html',
  styleUrls: ['./picture-item-detail.component.scss'],
})
export class InventoryPictureItemDetailComponent implements OnInit {
  @ViewChild('formRenderer')
  public formRenderer: CapturumFormRendererComponent;

  public modelId: string;
  public formKey = 'form_picture';
  public project: Project;

  constructor(
    private router: Router,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private formSaverService: FormSaverService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private projectService: ProjectService
  ) {
    this.modelId = this.dialogConfig.data?.item.id;
  }

  public async ngOnInit(): Promise<void> {
    this.project = await this.projectService.getProject();
  }

  public onSubmit(): void {
    this.formSaverService
      .submit(this.formKey)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close(true);

        const entity = this.translateService.instant('movers_complete.manage.quotation_attachment.file.label');

        this.toastService.success(
          this.translateService.instant('movers_complete.manage.quotation_attachment.file.label'),
          this.translateService.instant('movers_complete.entity.toast.updated', { entity })
        );

        this.router.navigateByUrl('/').then(() => {
          this.router.navigateByUrl(`/admin/project/${this.project.id}/pictures`);
        });
      });
  }

  public onCancel(): void {
    this.dialogRef.close();
  }
}
