<div class="ibox-title d-flex justify-content-between align-items-center">
  <h5>{{ 'movers_complete.inventory.pictures.title' | translate }}</h5>

  <cap-button styleClass="primary" [disabled]="disabled" (click)="addNewFile()">
    <i class="fa fa-plus"></i> {{ 'button.add' | translate }}
  </cap-button>
</div>

<div class="ibox-content" *ngIf="project">
  <cpb-list-renderer [key]="listKey" [context]="{ project_id: project?.id }"> </cpb-list-renderer>
</div>
